<template>
    <div class="expressJoinWarp">
        <div class="banner">
            <van-swipe  :autoplay="3000" indicator-color="white"  style="z-index: 0">
                <van-swipe-item  v-for="(item, index) in bannerList " :key="index" >
                    <img :src="item.url" alt="" class="width_height_full border_radius_common" >
                </van-swipe-item>
            </van-swipe>
        </div>

        <ul class="task">
            <li v-for="(item,index) in taskList" :key="index"  >
                <i class="taskName">{{item.taskName}}</i>
                <van-button @click="gotoFormPage(item.id)" class="finished" v-if="item.hasFinished === 1">已完成</van-button>
                <van-button @click="gotoFormPage(item.id)" class="notFinish" v-else>去完成</van-button>
            </li>
        </ul>
        <div class="eJCommitBtnDiV">
            <div class="eJCommitBtn" @click="commitAction">
                提交
            </div>
        </div>

    </div>
</template>

<script>
    import {getExpressStationApplyNo, getHomeInfo, getSubExpressList, submitExpressApply} from "../../utils/api";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "expressJoin",
        data(){
            return{
                bannerList:[],
                taskList:[
                   /* {"taskName":"1、申请人基本信息","id":1,"hasFinished":1},
            {"taskName":"2、申请站点信息","id":2,"hasFinished":0},
            {"taskName":"3、站点门头照","id":3,"hasFinished":0}*/
            ],
                applyNo:''
            }
        },
        mounted() {
            if (common.getItem("wtToken")){
                getHomeInfo().then(res=>{
                    if (res.code === 200 && res.data.businessCode === 200){
                        this.bannerList = res.data.homePage.expressApplyBanner
                    }
                })
                getExpressStationApplyNo({}).then(res=>{
                    if (res.code === 200 && res.data.businessCode === 200){
                        this.applyNo = res.data.data
                        let p = {applyNo:this.applyNo}
                        getSubExpressList(p).then(listRes=>{
                            if (listRes.data ){
                                this.taskList = listRes.data

                            }
                        })
                    }
                })

            }else {
                this.$router.push({
                    path:'/main/login',
                    query:{
                        back001:'1'
                    }
                })
            }
            uwStatisticAction('/expressJoin','0元加盟村口快递子任务列表')
        },
        methods:{
            gotoFormPage(id){
                this.$router.push({
                    path:'/eJI',
                    query: {
                        id,
                        applyNo:this.applyNo
                    }
                })

            },
            commitAction(){
                console.log("提交")
                let isFinished = false
                this.taskList.forEach((item,index)=>{
                    console.log('111:',item.hasFinished)
                    if (!item.hasFinished){
                        isFinished = false
                        this.$toast('请先完成以上内容')

                    }
                })
                if (isFinished){

                }
                let p = {
                    applyNo:this.applyNo
                }
                submitExpressApply(p).then(res=>{
                    if (res.code === 200 && res.data.businessCode === 200){
                        this.$router.push({
                            path:'/joinSuccess'
                        })
                    }

                })
            }
        }

    }
</script>

<style scoped lang="less">
    .expressJoinWarp{

        background: #f7f8f9;
        min-height: 100%;
        .banner{
            padding: 15px;
            background: #ffffff;
            border-radius: 12px;
            img{
                border-radius: 12px;
            }
        }
        .task{
            padding: 15px;
            background: #ffffff;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:14px 0 ;
                border-bottom: 1px solid #f7f8f9;
                .taskName{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .notFinish{
                    background: #3F7C53;
                    border-radius: 13px;
                    padding: 0 12px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    height: 25px;
                    line-height: 25px;
                }
                .finished{

                    background: #FFFFFF;
                    border-radius: 13px;
                    padding: 0 12px;
                    border: 1px solid #3F7C53;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #3F7C53;
                    height: 25px;
                    line-height: 25px;

                }
            }
            .van-swipe-item{
                width: 100% !important;
            }
        }

    }

</style>
<style lang="less">
    .eJCommitBtnDiV{
        position: absolute;
        bottom:0;
        left: 0;
        width: 100%;
        background: #ffffff;
        padding:15px   ;
    }
    .eJCommitBtn{

        width: 100%;

        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        height: 49px;
        line-height: 49px;
        background: #3F7C53;
        border-radius: 4px;

        text-align: center;

    }
    .eJCommitBtnActive{

        background: #3F7C53;
        opacity: 1;

    }
</style>